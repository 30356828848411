/**
 * @generated SignedSource<<91f5b32216bcbf04a25e7c92c44892be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_NavigationHeaderWithStudyQuery$variables = {
  study: string;
};
export type Navigation_NavigationHeaderWithStudyQuery$data = {
  readonly study: {
    readonly id: string;
    readonly name?: string;
    readonly organization?: {
      readonly name: string;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_NavigationProfileDropdown">;
};
export type Navigation_NavigationHeaderWithStudyQuery = {
  response: Navigation_NavigationHeaderWithStudyQuery$data;
  variables: Navigation_NavigationHeaderWithStudyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "study"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "study"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Navigation_NavigationHeaderWithStudyQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Navigation_NavigationProfileDropdown"
      },
      {
        "alias": "study",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Study",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Navigation_NavigationHeaderWithStudyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "study",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Study",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94c103aaef4c03106384453d0dee0978",
    "id": null,
    "metadata": {},
    "name": "Navigation_NavigationHeaderWithStudyQuery",
    "operationKind": "query",
    "text": "query Navigation_NavigationHeaderWithStudyQuery(\n  $study: ID!\n) {\n  ...Navigation_NavigationProfileDropdown\n  study: node(id: $study) {\n    __typename\n    id\n    ... on Study {\n      name\n      organization {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment Navigation_NavigationProfileDropdown on Query {\n  viewer {\n    image\n    email\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f6b8c4a1d0d364c341daf2493dc58fd";

export default node;
