// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"NvtG0ICLewVlqR9wUJtyU"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

import { isDevelopment, isTest, shouldInitializeSentry } from "@/lib/env";

if (shouldInitializeSentry()) {
  Sentry.init({
    parentSpanIsAlwaysRootSpan: false,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.browserTracingIntegration({
        beforeStartSpan: (context) => {
          return {
            ...context,
            // A lot of our sentry traces are ungrouped since we use nodeIds in the path. e.g.
            //.http://dewrangle.com/T3JnYW5pemF0aW9uOmNsd3oyYXVzaTAwMGFpcjB1ZjY2Zmxia3k%3D
            // This means two different organization page loads will be named as different transactions,
            // when really they're the same activity. The following code will group these behaviours by
            // replacing the node IDs with named placeholders. so
            // http://dewrangle.com/T3JnYW5pemF0aW9uOmNsd3oyYXVzaTAwMGFpcjB1ZjY2Zmxia3k%3D/U3R1ZHk6Y2x3ejJkcXZvMDAwOXRvd3RwZHdjeWNkcA%3D%3D/volumes/Vm9sdW1lOmNseDBwZHVlejAwMGg3aGl0Y215NGcybTE%3D
            // becomes
            // http://dewrangle.com/[organization]/[study]/volumes/[volume]
            name: location.pathname
              // Match three base64-encoded parts separated by slashes, followed by volumes segment and more path segments
              .replace(
                /\/([A-Za-z0-9+%]{20,})\/([A-Za-z0-9+%]{20,})\/volumes\/([A-Za-z0-9+%]{20,})(\/.*)?$/,
                "/[organization]/[study]/volumes/[volume]$4"
              )
              // Match two base64-encoded parts separated by a slash, optionally followed by more path segments
              .replace(
                /\/([A-Za-z0-9+%]{20,})\/([A-Za-z0-9+%]{20,})(\/.*)?$/,
                "/[organization]/[study]$3"
              )
              // Match a single base64-encoded part, optionally followed by more path segments
              .replace(/\/([A-Za-z0-9+%]{20,})(\/.*)?$/, "/[organization]$2"),
          };
        },
      }),
    ],

    beforeSend(event, hint) {
      if (isDevelopment() || isTest()) {
        // eslint-disable-next-line no-console
        console.error(hint.originalException ?? hint.syntheticException);
        return process.env.NEXT_PUBLIC_ENABLE_SENTRY_DEV === "1" ? event : null;
      }
      return event;
    },

    tracePropagationTargets: [
      "localhost",
      /^\//,
      /^https:\/\/(dewrangle-\d+\.fly\.dev|dewrangle\.com)\/api/,
    ],
  });
}
