import { ChevronRight, Package } from "lucide-react";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Children, ComponentPropsWithoutRef, ReactNode, Suspense } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { clsxm } from "@peykio/clsxm";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@dewrangle/ui";

import { IQuickLinkProps, Layout, QuickLink } from "@/components/uikit";
import type { Navigation_NavigationHeaderQuery } from "@/queries/__generated__/Navigation_NavigationHeaderQuery.graphql";
import type { Navigation_NavigationHeaderWithOrganizationQuery } from "@/queries/__generated__/Navigation_NavigationHeaderWithOrganizationQuery.graphql";
import type { Navigation_NavigationHeaderWithStudyQuery } from "@/queries/__generated__/Navigation_NavigationHeaderWithStudyQuery.graphql";
import type { Navigation_NavigationHeaderWithVolumeQuery } from "@/queries/__generated__/Navigation_NavigationHeaderWithVolumeQuery.graphql";
import type { Navigation_NavigationProfileDropdown$key } from "@/queries/__generated__/Navigation_NavigationProfileDropdown.graphql";

export const Row = ({
  className = "",
  ...rest
}: JSX.IntrinsicElements["div"]) => (
  <div
    className={clsxm("flex items-center justify-start", {
      [className]: className,
    })}
    {...rest}
  />
);

export const NavigationLink = ({
  className,
  ...props
}: IQuickLinkProps & { className?: string }) => (
  <Row className={clsxm(["gap-x-2", className])}>
    <QuickLink
      {...props}
      className="truncate underline-offset-4 focus:outline-none hover:underline focus:underline"
    />
  </Row>
);

export const NavigationProfileDropdown = ({
  queryRef,
}: {
  queryRef: Navigation_NavigationProfileDropdown$key;
}) => {
  const query = useFragment(
    graphql`
      fragment Navigation_NavigationProfileDropdown on Query {
        viewer {
          image
          email
          name
        }
      }
    `,
    queryRef
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="outline rounded-full overflow-hidden"
        aria-label="Profile Dropdown"
      >
        <Avatar className="h-8 w-8">
          <AvatarImage src={query.viewer?.image || ""} />
          <AvatarFallback>{query.viewer?.name}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <Link href="/" passHref>
          <DropdownMenuItem>Dashboard</DropdownMenuItem>
        </Link>
        <Link href="/profile/settings" passHref>
          <DropdownMenuItem>Settings</DropdownMenuItem>
        </Link>
        <Link href="/" passHref>
          <DropdownMenuItem onClick={() => signOut()}>Logout</DropdownMenuItem>
        </Link>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const NavigationBreadcrumbs = ({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) => (
  <Row className={clsxm("gap-x-4", { [className]: className?.length })}>
    {Children.map(children, (child, i) =>
      child ? (
        <>
          {i !== 0 && (
            <div>
              <ChevronRight className="h-4 w-4 text-muted-foreground" />
            </div>
          )}
          {child}
        </>
      ) : null
    )}
  </Row>
);

export type NavigationBarProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  newline?: React.ReactNode;
} & JSX.IntrinsicElements["header"];

export const NavigationBar = ({
  children,
  className = "",
  left,
  right,
  newline,
  ...rest
}: NavigationBarProps): JSX.Element => (
  <header
    className={clsxm("flex items-center", { [className]: className?.length })}
    {...rest}
  >
    <Layout.Grid className="h-full border-b border-accent py-4">
      {left && (
        <div className="col-span-3 flex items-center">
          <Layout.Row>{left}</Layout.Row>
        </div>
      )}
      <div
        className={clsxm("hidden md:flex items-center space-x-6 col-span-12", {
          "col-span-11": right || left,
          "col-span-6": right && left,
        })}
      >
        {children}
      </div>
      {right && (
        <div className="flex items-center col-start-12 col-span-1 justify-end gap-x-4">
          {right}
        </div>
      )}
      {newline && <div className="col-span-full">{newline}</div>}
    </Layout.Grid>
  </header>
);

const NavigationHeaderEmpty = () => {
  return (
    <NavigationBar
      right={
        <Avatar className="h-8 w-8 outline rounded-full">
          <AvatarImage src="" alt="loading" />
          <AvatarFallback>?</AvatarFallback>
        </Avatar>
      }
      className="col-span-9"
    >
      <NavigationBreadcrumbs className="w-full">
        <Link href="/" passHref>
          <Package className="h-6 w-6 text-primary" aria-label="Home" />
        </Link>
      </NavigationBreadcrumbs>
    </NavigationBar>
  );
};

const NavigationHeaderContent = ({
  children,
}: ComponentPropsWithoutRef<"header">) => {
  const query = useLazyLoadQuery<Navigation_NavigationHeaderQuery>(
    graphql`
      query Navigation_NavigationHeaderQuery {
        ...Navigation_NavigationProfileDropdown
      }
    `,
    {}
  );

  return (
    <NavigationBar
      right={<NavigationProfileDropdown queryRef={query} />}
      newline={children && <Layout.Row>{children}</Layout.Row>}
      className="col-span-9"
    >
      <NavigationBreadcrumbs className="w-full">
        <Link href="/" passHref>
          <Package className="h-6 w-6 text-primary" aria-label="Home" />
        </Link>
      </NavigationBreadcrumbs>
    </NavigationBar>
  );
};

const NavigationHeaderWithOrganizationContent = ({
  children,
}: ComponentPropsWithoutRef<"header">) => {
  const router = useRouter();
  const query =
    useLazyLoadQuery<Navigation_NavigationHeaderWithOrganizationQuery>(
      graphql`
        query Navigation_NavigationHeaderWithOrganizationQuery(
          $organization: ID!
        ) {
          ...Navigation_NavigationProfileDropdown
          organization: node(id: $organization) {
            id
            ... on Organization {
              name
            }
          }
        }
      `,
      { organization: (router.query.organization ?? "") as string }
    );

  return (
    <NavigationBar
      right={<NavigationProfileDropdown queryRef={query} />}
      newline={children && <Layout.Row>{children}</Layout.Row>}
      className="col-span-9"
    >
      <NavigationBreadcrumbs className="w-full">
        <Link href="/" passHref>
          <Package className="h-6 w-6 text-primary" aria-label="Home" />
        </Link>
        <NavigationLink
          className="max-w-[93%]"
          href="/[organization]"
          pick={["organization"]}
        >
          {query?.organization?.name}
        </NavigationLink>
      </NavigationBreadcrumbs>
    </NavigationBar>
  );
};

const NavigationHeaderWithStudyContent = ({
  children,
}: ComponentPropsWithoutRef<"header">) => {
  const router = useRouter();
  const query = useLazyLoadQuery<Navigation_NavigationHeaderWithStudyQuery>(
    graphql`
      query Navigation_NavigationHeaderWithStudyQuery($study: ID!) {
        ...Navigation_NavigationProfileDropdown
        study: node(id: $study) {
          id
          ... on Study {
            name
            organization {
              name
            }
          }
        }
      }
    `,
    {
      study: router.query.study as string,
    }
  );

  return (
    <NavigationBar
      right={<NavigationProfileDropdown queryRef={query} />}
      newline={children && <Layout.Row>{children}</Layout.Row>}
      className="col-span-9"
    >
      <NavigationBreadcrumbs className="w-full">
        <Link href="/" passHref>
          <Package className="h-6 w-6 text-primary" aria-label="Home" />
        </Link>
        <NavigationLink
          className="max-w-[40%]"
          href="/[organization]"
          pick={["organization"]}
        >
          {query?.study?.organization?.name}
        </NavigationLink>
        <NavigationLink
          className="max-w-[45%]"
          href="/[organization]/[study]"
          pick={["organization", "study"]}
        >
          {query?.study?.name}
        </NavigationLink>
      </NavigationBreadcrumbs>
    </NavigationBar>
  );
};

const NavigationHeaderWithVolumeContent = ({
  children,
}: ComponentPropsWithoutRef<"header">) => {
  const router = useRouter();
  const query = useLazyLoadQuery<Navigation_NavigationHeaderWithVolumeQuery>(
    graphql`
      query Navigation_NavigationHeaderWithVolumeQuery($volume: ID!) {
        ...Navigation_NavigationProfileDropdown
        volume: node(id: $volume) {
          id
          ... on Volume {
            name
            study {
              name
              organization {
                name
              }
            }
          }
        }
      }
    `,
    {
      volume: router.query.volume as string,
    }
  );

  return (
    <NavigationBar
      right={<NavigationProfileDropdown queryRef={query} />}
      newline={children && <Layout.Row>{children}</Layout.Row>}
      className="col-span-9"
    >
      <NavigationBreadcrumbs className="w-full">
        <Link href="/" passHref>
          <Package className="h-6 w-6 text-primary" aria-label="Home" />
        </Link>
        <NavigationLink
          className="max-w-[27%]"
          href="/[organization]"
          pick={["organization"]}
        >
          {query?.volume?.study?.organization?.name}
        </NavigationLink>
        <NavigationLink
          className="max-w-[27%]"
          href="/[organization]/[study]"
          pick={["organization", "study"]}
        >
          {query?.volume?.study?.name}
        </NavigationLink>
        <NavigationLink
          className="max-w-[26%]"
          href="/[organization]/[study]/volumes/[volume]"
          pick={["organization", "study", "volume"]}
        >
          {query?.volume?.name}
        </NavigationLink>
      </NavigationBreadcrumbs>
    </NavigationBar>
  );
};

export const NavigationHeader = () => (
  <Suspense fallback={<NavigationHeaderEmpty />}>
    <NavigationHeaderContent />
  </Suspense>
);

export const NavigationHeaderWithOrganization = () => (
  <Suspense fallback={<NavigationHeaderEmpty />}>
    <NavigationHeaderWithOrganizationContent />
  </Suspense>
);

export const NavigationHeaderWithStudy = () => (
  <Suspense fallback={<NavigationHeaderEmpty />}>
    <NavigationHeaderWithStudyContent />
  </Suspense>
);

export const NavigationHeaderWithVolume = () => (
  <Suspense fallback={<NavigationHeaderEmpty />}>
    <NavigationHeaderWithVolumeContent />
  </Suspense>
);
