import {
  ArrowDown01,
  ArrowDown10,
  ArrowDownAZ,
  ArrowDownZA,
  LucideIcon,
} from "lucide-react";
import React from "react";

export type SortType = "alpha" | "binary";
export type SortOrder = "asc" | "desc";

export const sortIcons: Record<SortType, Record<SortOrder, LucideIcon>> = {
  alpha: {
    asc: ArrowDownAZ,
    desc: ArrowDownZA,
  },
  binary: {
    asc: ArrowDown01,
    desc: ArrowDown10,
  },
};

export const SortOption = ({
  children,
  order,
  type = "alpha",
}: {
  children: React.ReactNode;
  order: SortOrder;
  type?: SortType;
}) => {
  const Icon = sortIcons[type][order];
  return (
    <span className="block truncate">
      <Icon
        size={16}
        className="inline mr-1"
        aria-label={order === "asc" ? "Ascending" : "Descending"}
      />{" "}
      {children}
    </span>
  );
};
