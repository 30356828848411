/**
 * @generated SignedSource<<0c9f86daa66bcaa55ad9875f2e968f2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_NavigationHeaderWithOrganizationQuery$variables = {
  organization: string;
};
export type Navigation_NavigationHeaderWithOrganizationQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly name?: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_NavigationProfileDropdown">;
};
export type Navigation_NavigationHeaderWithOrganizationQuery = {
  response: Navigation_NavigationHeaderWithOrganizationQuery$data;
  variables: Navigation_NavigationHeaderWithOrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organization"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organization"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/)
  ],
  "type": "Organization",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Navigation_NavigationHeaderWithOrganizationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Navigation_NavigationProfileDropdown"
      },
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Navigation_NavigationHeaderWithOrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f6493cf7112dddcc77ab555a7a42115",
    "id": null,
    "metadata": {},
    "name": "Navigation_NavigationHeaderWithOrganizationQuery",
    "operationKind": "query",
    "text": "query Navigation_NavigationHeaderWithOrganizationQuery(\n  $organization: ID!\n) {\n  ...Navigation_NavigationProfileDropdown\n  organization: node(id: $organization) {\n    __typename\n    id\n    ... on Organization {\n      name\n    }\n  }\n}\n\nfragment Navigation_NavigationProfileDropdown on Query {\n  viewer {\n    image\n    email\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "acb17c77cb5604b7495411e25216244d";

export default node;
